// クリニック関連処理
var ZENJINKAI_CLINIC = ZENJINKAI_CLINIC || {};
// QA関連の処理
ZENJINKAI_CLINIC.QA = {};
ZENJINKAI_CLINIC.QA.init = function() {

  // デフォルト値の定義 - START /////////////////
  var _QA_URL = '/api/qa/';
  // デフォルト値の定義 - END ///////////////////

  return {
    execute: function() {
      var $dialysis        = $('#js_qa_dialysis');
      var $family          = $('#js_qa_family');
      var $hospitalization = $('#js_qa_hospitalization');

      _setup($dialysis, 1);
      _setup($family, 2);
      _setup($hospitalization, 3);

      function _setup($target, type) {

        $target.empty();
        if ($target.length > 0) {
          $.ajax({
            'url'      : _QA_URL + '?type=' + type,
            'type'     : 'get',
            'dataType' : 'json',
            'error'    : function() {
            },
            'success'  : function(json) {
              if (json) {
                for(var i=0,max=json.length; i<max; i++) {
                  var code = json[i].code;
                  var $item = $(code);
                  $target.append($item);

                  var $js_accordion_button = $item.find('.js_accordion_button');
                  var $js_accordion_content = $item.find('.js_accordion_content');

                  $js_accordion_button.on('click', function(){
                    $(this).toggleClass('is_active');
                    $(this).next('.js_accordion_content').slideToggle();
                  });

                }
              }
            }
          });
        }
      }
    }
  }
  // Private Function - START ///////////////////
};
